import { Environments } from './environments.interface';

export const development: Environments = {
	baseUrl: 'https://tprofil.mrm-etagi.com',
	apiUrl: 'https://tprofil.mrm-etagi.com/api',

	// reportsApiUrl: 'https://key-indicators.data-etagi.ru/reports',
	reportsApiUrl: 'https://new.etagidata.ru/reports',
	aggregatorsApiUrl: 'https://aggregators-backend.data-etagi.ru',
	demarkBotApiUrl: 'https://markbot.etagi.pro/api',

	reportsEtagiProApiUrl: 'https://etagi.pro/api/dashboard',

	leadsMonitorBaseUrl: 'https://tblmonitor.mrm-etagi.com',
	leadsMonitorApiUrl: 'https://tblmonitor.mrm-etagi.com/api',
	leadsMonitorWsUrl: 'wss://tblmonitor.mrm-etagi.com/ws',

	handbooksApiUrl: 'https://thandbook.mrm-etagi.com/api',

	chatGptApiUrl: 'https://new.etagidata.ru/mrm/chatgpt',

	budgetsBaseUrl: 'https://tbdg.mrm-etagi.com',
	budgetsApiUrl: 'https://tbdg.mrm-etagi.com/api',
	budgetsWsUrl: 'wss://tbdg.mrm-etagi.com/ws',

	settingsApiUrl: 'https://tsetting.mrm-etagi.com/api',
};
